// AnalysisWorkbench.js - React Component for Matching Game Tiles

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Grid, Typography, Paper, MenuItem, Select, InputLabel, FormControl, CircularProgress, Divider, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import './TileMatcher.css';

const AnalysisWorkbench = () => {
    const [screenshots, setScreenshots] = useState([]);
    const [websites, setWebsites] = useState([]);
    const [games, setGames] = useState([]);
    const [studios, setStudios] = useState([]);
    const [selectedScreenshotIndex, setSelectedScreenshotIndex] = useState(0);
    const [tiles, setTiles] = useState([]);
    const [tileImages, setTileImages] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [websiteName, setWebsiteName] = useState('');
    const [selectedTileIndex, setSelectedTileIndex] = useState(null);
    const [gameName, setGameName] = useState('');
    const [studio, setStudio] = useState('');
    const [loading, setLoading] = useState(false);
    const [gameMatch, setGameMatch] = useState(false);
    const [studioMatch, setStudioMatch] = useState(false);
    const [newGameDialogOpen, setNewGameDialogOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsLoading, setSuggestionsLoading] = useState(false);

    // Normalize strings for comparison
    const normalizeString = (str) => str.trim().toLowerCase();

    //Used by the new game dialog
    const [newStudioName, setNewStudioName] = useState(''); // Tracks the name of a new studio entered by the user
    const [selectedStudioId, setSelectedStudioId] = useState('unknown'); // Tracks the selected studio ID from the dropdown



    //Function to get the Games and Studios list - can be used whereever
    const fetchGamesAndStudios = async () => {
        try {
            setLoading(true);
            const gamesResponse = await axios.get(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/games`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
                },
            });
            console.log('Fetched games:', gamesResponse.data);
            setGames(Array.isArray(gamesResponse.data.games) ? gamesResponse.data.games : []);
            
            const studiosResponse = await axios.get(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/studios`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
                },
            });
            console.log('Fetched studios:', studiosResponse.data);
            setStudios(Array.isArray(studiosResponse.data.studios) ? studiosResponse.data.studios : []);
        } catch (error) {
            console.error('Error fetching games or studios:', error);
        } finally {
            setLoading(false);
        }
    };


    // Function to fetch tiles from analysis
    // Filters out COMPLETE status
    const fetchTilesFromAnalysis = async () => {
        const selectedScreenshot = screenshots[selectedScreenshotIndex];
        
        console.log('Selected screenshot:', selectedScreenshot);

        if (!selectedScreenshot || !selectedScreenshot.id) {
            console.warn('No valid screenshot ID available for selected screenshot:', selectedScreenshot);
            setTiles([]);
            return;
        }
        
        const screenshotId = typeof selectedScreenshot.id === 'object' ? selectedScreenshot.id.S : selectedScreenshot.id;
        console.log('Fetching analysis for screenshotId:', screenshotId);

        try {
            setLoading(true);
            // Fetch analysis for the screenshot ID
            const response = await axios.get(`${process.env.REACT_APP_API_ANALYST_URL}/get-unconfirmed-analysis/${screenshotId}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_ANALYST_KEY,
                },
            });
            const analysisData = response.data;
            console.log('Fetched analysis data:', analysisData);

            // Update to properly handle bounding box data structure
            if (Array.isArray(analysisData) && analysisData.length > 0) {
                const tilesData = analysisData
                .filter((tile) => tile.Status?.S !== "COMPLETE")  // Filter out tiles with Status "COMPLETE"
                .map((tile) => {
                    return {
                        WebsitesDataID: tile.WebsitesDataID?.S, // Add WebsitesDataID
                        BoundingBoxID: tile.BoundingBoxID?.S,   // Add BoundingBoxID
                        BoundingBox: tile.BoundingBox,
                        Confidence: parseFloat(tile.Confidence?.N),
                        RecognizedText: tile.RecognizedText?.S,
                        stage: tile.stage?.S,
                        Status: tile.Status?.S,
                        imageUrl: tile.imageUrl?.S, // Assuming the tile has the image URL as a key
                        AnalysedText: tile.AnalysedText?.M, // Add analyzed text details
                    };
                });
            
                setTiles(tilesData);
                processTileImages(tilesData, selectedScreenshot.ImageLocation?.S);
            } else {
                console.warn('No bounding boxes found in analysis data.');
                setTiles([]);
                setTileImages([]);
            }
        } catch (error) {
            console.error('Error fetching analysis data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateNewGame = async () => {
        try {
            let gameID = '';
            let studioID = '';
            const normalizedGameName = normalizeString(gameName);
    
            if (newStudioName) {
                // Create a new studio and game
                const response = await axios.post(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/create-studio-and-game`, {
                    StudioName: newStudioName,
                    GameName: normalizedGameName, // Use normalized game name
                }, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
                    },
                });
    
                studioID = response.data.StudioID;
                gameID = response.data.GameID;
    
            } else {
                // Create a new game using an existing studio
                const response = await axios.post(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/games`, {
                    Name: normalizedGameName, // Use normalized game name
                    StudioID: selectedStudioId === 'unknown' ? 'unknown' : selectedStudioId // Allow 'unknown' as StudioID if selected
                }, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
                    },
                });
    
                studioID = selectedStudioId === 'unknown' ? 'unknown' : selectedStudioId;
                gameID = response.data.GameID;
            }
    
            // Use selectedTileIndex to assign the new game to the tile
            if (selectedTileIndex !== null) {
                const selectedTile = tileImages[selectedTileIndex];
                if (selectedTile) {
                    const requestBody = {
                        WebsitesDataID: selectedTile.WebsitesDataID,
                        BoundingBoxID: selectedTile.BoundingBoxID,
                        GameID: gameID,
                        Status: "COMPLETE",
                        stage: "ManualMatch",
                    };
    
                    await axios.post(`${process.env.REACT_APP_API_ANALYST_URL}/set-unconfirmed-analysis`, requestBody, {
                        headers: {
                            'x-api-key': process.env.REACT_APP_API_ANALYST_KEY,
                        },
                    });
    
                    // Refresh tiles and games/studios after creating new game
                    await fetchTilesFromAnalysis();
                    await fetchGamesAndStudios();
                }
            }
        } catch (error) {
            console.error('Error creating new game:', error);
        } finally {
            setNewGameDialogOpen(false);
        }
    };
    



    // Fetch unique website names on initial load
    useEffect(() => {
        const fetchWebsites = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/websites/unique-names`, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                    },
                });
                console.log('Fetched websites:', response.data);
                setWebsites(response.data);
                if (response.data.length > 0) {
                    setWebsiteName(response.data[0]); // Default to the first website
                }
            } catch (error) {
                console.error('Error fetching websites:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchWebsites();
    }, []);

    // Fetch existing games and studios on initial load
    useEffect(() => {
 
        fetchGamesAndStudios();
    }, []);

    // Fetch screenshots for the selected date and website
    useEffect(() => {
        if (!websiteName) return;

        const fetchScreenshots = async () => {
            console.log('Fetching screenshots for date:', selectedDate, 'and websiteName:', websiteName);

            // Convert selectedDate into startDate and endDate for the entire day
            const startDate = new Date(selectedDate);
            startDate.setUTCHours(0, 0, 0, 0);
            const endDate = new Date(selectedDate);
            endDate.setUTCHours(23, 59, 59, 999);

            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/screenshots`, {
                    params: {
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                        websiteName,
                    },
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                    },
                });
                console.log('Screenshots fetched:', response.data.screenshots);
                setScreenshots(response.data.screenshots);
                if (response.data.screenshots.length > 0) {
                    setSelectedScreenshotIndex(0);
                }
            } catch (error) {
                console.error('Error fetching screenshots:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchScreenshots();
    }, [selectedDate, websiteName]);

    // Fetch tiles using the analysis results for the selected screenshot
    useEffect(() => {
        if (screenshots.length === 0) return;
    

        fetchTilesFromAnalysis();
    }, [screenshots, selectedScreenshotIndex]);

    // Function to get a signed URL for an S3 object
    const getSignedUrl = async (s3Url) => {
        if (!s3Url) return '';
        console.log('Getting signed URL for s3Url:', s3Url);
        try {
            // Extract the key from the s3Url
            const key = s3Url.replace('s3://', '').split('/').slice(1).join('/');

            const apiUrl = `${process.env.REACT_APP_API_URL}/screenshotssigned/${encodeURIComponent(key)}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                },
            });

            if (response.status !== 200) {
                console.error('Error response from signed URL API:', response.statusText);
                return '';
            }

            // Get the signed URL as plain text
            const signedUrl = response.data;
            console.log('Received signed URL:', signedUrl);

            return signedUrl;
        } catch (error) {
            console.error('Error getting signed URL:', error);
            return '';
        }
    };

    // Function to process tile images using bounding boxes
    const processTileImages = async (analysisResults, imageUrl) => {
        if (!imageUrl || !analysisResults || analysisResults.length === 0) {
            setTileImages([]);
            return;
        }
    
        const signedImageUrl = await getSignedUrl(imageUrl);
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = signedImageUrl;
    
        img.onload = () => {
            const tileImagesArray = analysisResults.map((box) => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
    
                // Calculate the dimensions of the tile
                const sx = img.width * parseFloat(box.BoundingBox?.M?.Left?.N);
                const sy = img.height * parseFloat(box.BoundingBox?.M?.Top?.N);
                const sWidth = img.width * parseFloat(box.BoundingBox?.M?.Width?.N);
                const sHeight = img.height * parseFloat(box.BoundingBox?.M?.Height?.N);
    
                canvas.width = sWidth;
                canvas.height = sHeight;
    
                ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, sWidth, sHeight);
    
                // Get the data URL of the cropped image
                const dataUrl = canvas.toDataURL();
    
                // Return the data URL along with the original dimensions
                return {
                    dataUrl,
                    width: sWidth,
                    height: sHeight,
                    RecognizedText: box.RecognizedText,
                    Confidence: box.Confidence,
                    Status: box.Status,
                    stage: box.stage,
                    AnalysedText: box.AnalysedText, // Include analyzed text details
                    WebsitesDataID: box.WebsitesDataID, // Add WebsitesDataID
                    BoundingBoxID: box.BoundingBoxID,   // Add BoundingBoxID
                };
            });
    
            setTileImages(tileImagesArray);
        };
    
        img.onerror = (error) => {
            console.error('Error loading image for tile processing:', error);
            setTileImages([]);
        };
    };

    const handleDateChange = (event) => {
        console.log('Date changed to:', event.target.value);
        setSelectedDate(event.target.value);
    };

    const handleNextScreenshot = () => {
        console.log('Navigating to next screenshot');
        if (selectedScreenshotIndex < screenshots.length - 1) {
            setSelectedScreenshotIndex(selectedScreenshotIndex + 1);
        }
    };

    const handlePreviousScreenshot = () => {
        console.log('Navigating to previous screenshot');
        if (selectedScreenshotIndex > 0) {
            setSelectedScreenshotIndex(selectedScreenshotIndex - 1);
        }
    };

    const handleWebsiteChange = (event) => {
        console.log('Website changed to:', event.target.value);
        setWebsiteName(event.target.value);
    };

    const handleTileClick = async (index) => {
        console.log('Tile clicked:', index);
        setSelectedTileIndex(index);
        const selectedTile = tiles[index];
    
        // Log the selectedTile object to inspect its structure
        console.log('Selected Tile Data:', selectedTile);
    
        if (selectedTile) {
            setGameName(selectedTile.AnalysedText?.GameName?.S || '');
            setStudio(selectedTile.AnalysedText?.Studio?.S !== 'unknown' ? selectedTile.AnalysedText?.Studio?.S : '');
    
            // Perform fuzzy search using name-suggestions API
            try {
                setSuggestionsLoading(true); // Start loading
                console.log('Selected Tile GameName:', selectedTile.AnalysedText?.GameName?.S);
    
                const response = await axios.post(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/name-suggestions`, {
                    GameName: selectedTile.AnalysedText?.GameName?.S || ''
                }, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY
                    }
                });
                console.log('Name suggestions fetched:', response.data);
    
                // Structure the response correctly for the component
                const formattedSuggestions = {
                    matches: response.data.matches || [] // Ensure we always have an array, even if no matches
                };
    
                setSuggestions(formattedSuggestions);
            } catch (error) {
                console.error('Error fetching name suggestions:', error);
                setSuggestions({ matches: [] }); // Set empty matches in case of an error
            } finally {
                setSuggestionsLoading(false); // End loading
            }
        }
    };
    
    // Function to assign the selected suggestion
    const handleAssignSuggestion = async (suggestion) => {
        if (selectedTileIndex !== null) {
            const selectedTile = tileImages[selectedTileIndex];
            if (selectedTile) {
                try {
                    console.log('Assigning suggestion:', suggestion);
                    const requestBody = {
                        WebsitesDataID: selectedTile.WebsitesDataID, // Assuming WebsitesDataID is part of each tile's data
                        BoundingBoxID: selectedTile.BoundingBoxID, // Assuming BoundingBoxID is part of each tile's data
                        GameID: suggestion.GameID,
                        Status: "COMPLETE",
                        stage: "ManualMatch",
                    };
                    console.log('Assign Suggestion body:', requestBody);

    
                    const response = await axios.post(`${process.env.REACT_APP_API_ANALYST_URL}/set-unconfirmed-analysis`, requestBody, {
                        headers: {
                            'x-api-key': process.env.REACT_APP_API_ANALYST_KEY,
                        },
                    });
                    console.log('Successfully assigned suggestion:', response.data);
                    await fetchTilesFromAnalysis();  //update list of tiles
                    await fetchTilesFromAnalysis();  // Refresh tiles after assignment
                    await fetchGamesAndStudios();    // Refresh games and studios after assignment 
                    console.log('Successfully assigned suggestion:', response.data);
                } catch (error) {
                    console.error('Error assigning suggestion:', error);
                }
            } else {
                console.error("No selected tile found.");
            }
        } else {
            console.error("No tile selected for assignment.");
        }
    };

    // Effect to check matches for game and studio when user clicks on a tile or updates inputs
    useEffect(() => {
        if (Array.isArray(games) && Array.isArray(studios)) {
            console.log('Games array:', games);
            console.log('Studios array:', studios);

            const normalizedGameName = normalizeString(gameName);
            const normalizedStudio = normalizeString(studio);

            if (games && Array.isArray(games)) {
                const gameFound = games.some((game) => normalizeString(game.Name) === normalizedGameName);
                setGameMatch(gameFound);
                if (gameFound) {
                    const matchedGame = games.find((game) => normalizeString(game.Name) === normalizedGameName);
                    if (matchedGame && matchedGame.StudioID) {
                        const matchedStudio = studios.find((studio) => normalizeString(studio.StudioID) === normalizeString(matchedGame.StudioID));
                        if (matchedStudio) {
                            console.log('Setting studio name based on game match:', matchedStudio.Name);
                            setStudio(matchedStudio.Name);
                        }
                    }
                }
            } else {
                setGameMatch(false);
            }

            if (studios && Array.isArray(studios)) {
                setStudioMatch(studios.some((stu) => normalizeString(stu.Name) === normalizedStudio));
            } else {
                setStudioMatch(false);
            }
        }
    }, [gameName, studio, games, studios]);

    // Function to manually assign the game and studio from manual entry
    const handleManualAssign = async () => {
        if (selectedTileIndex !== null) {
            const selectedTile = tileImages[selectedTileIndex];
            if (selectedTile) {
                try {
                    console.log('Manually assigning game and studio:', gameName, studio);
                    
                    // Find GameID and StudioID based on the entered names
                    const matchedGame = games.find((game) => normalizeString(game.Name) === normalizeString(gameName));
                    const matchedStudio = studios.find((stu) => normalizeString(stu.Name) === normalizeString(studio));

                    if (!matchedGame || !matchedStudio) {
                        console.error('No matching game or studio found.');
                        return;
                    }

                    const requestBody = {
                        WebsitesDataID: selectedTile.WebsitesDataID, // Assuming WebsitesDataID is part of each tile's data
                        BoundingBoxID: selectedTile.BoundingBoxID,   // Assuming BoundingBoxID is part of each tile's data
                        GameID: matchedGame.GameID,
                        Status: "COMPLETE",
                        stage: "ManualMatch",
                    };

                    const response = await axios.post(`${process.env.REACT_APP_API_ANALYST_URL}/set-unconfirmed-analysis`, requestBody, {
                        headers: {
                            'x-api-key': process.env.REACT_APP_API_ANALYST_KEY,
                        },
                    });
                    console.log('Successfully manually assigned game and studio:', response.data);
                    await fetchTilesFromAnalysis();  //update list of tiles
                    await fetchTilesFromAnalysis();  // Refresh tiles after assignment
                    await fetchGamesAndStudios();    // Refresh games and studios after assignment 
                } catch (error) {
                    console.error('Error manually assigning game and studio:', error);
                }
            } else {
                console.error("No selected tile found.");
            }
        } else {
            console.error("No tile selected for manual assignment.");
        }
    };




    const handleOpenNewGameDialog = () => {
        setNewGameDialogOpen(true);
    };

    const handleCloseNewGameDialog = () => {
        setNewGameDialogOpen(false);
    };

    return (
        <Box sx={{ padding: 3 }}>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h6">Date:</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            type="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel shrink>Website</InputLabel>
                            <Select
                                value={websiteName}
                                onChange={handleWebsiteChange}
                                label="Website"
                            >
                                {websites.map((website, index) => (
                                    <MenuItem key={index} value={website}>{website}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={handlePreviousScreenshot}
                            disabled={selectedScreenshotIndex === 0}
                        >
                            Previous Screenshot
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            Screenshot {selectedScreenshotIndex + 1} of {screenshots.length}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={handleNextScreenshot}
                            disabled={selectedScreenshotIndex === screenshots.length - 1}
                        >
                            Next Screenshot
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Box>
                        {tileImages.length > 0 ? (
                            tileImages.map((tile, index) => (
                                <Paper
                                    key={index}
                                    elevation={2}
                                    sx={{ padding: 2, marginBottom: 2, cursor: 'pointer', border: selectedTileIndex === index ? '2px solid blue' : 'none' }}
                                    onClick={() => handleTileClick(index)}
                                >
                                    <img
                                        src={tile.dataUrl}
                                        alt={tile.RecognizedText}
                                        className="tile-image"
                                        style={{
                                            width: '100%',
                                            maxHeight: '150px',
                                            objectFit: 'contain',
                                            transition: 'transform 0.3s ease-in-out',
                                            transform: 'scale(1)',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.transform = 'scale(2)';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.transform = 'scale(1)';
                                        }}
                                    />
                                    <Typography variant="body2">Detected Type: {tile.AnalysedText?.TileType?.S || 'Unknown'}</Typography>
                                    <Typography variant="body2">Detected Name: {tile.AnalysedText?.GameName?.S || 'Unknown'}</Typography>
                                    <Typography variant="body2">Detected Studio: {tile.AnalysedText?.Studio?.S || 'Unknown'}</Typography>
                                    <Typography variant="body2">Stage: {tile.stage}</Typography>
                                    <Typography variant="body2">Status: {tile.Status}</Typography>
                                </Paper>
                            ))
                        ) : (
                            <Typography variant="body2">No tiles available for the selected screenshot.</Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ padding: 3 }}>
                        <Typography variant="h6">Name Suggestions</Typography>
                        {suggestionsLoading ? (
                            <CircularProgress />
                        ) : (
                            suggestions && suggestions.matches && suggestions.matches.length > 0 ? (
                                suggestions.matches.map((suggestion, index) => (
                                    <Box key={index} sx={{ marginBottom: 2 }}>
                                        {console.log('Suggestion:', suggestion)}
                                        <Typography variant="body1">Game Name: {suggestion.Name}</Typography>
                                        <Typography variant="body1">Studio Name: {suggestion.StudioName || 'Unknown'}</Typography>
                                        <Typography variant="body1">Confidence: {suggestion.Confidence ? suggestion.Confidence.toFixed(2) : 'N/A'}%</Typography>
                                        <Button
                                            variant="contained"
                                            sx={{ marginTop: 1 }}
                                            onClick={() => handleAssignSuggestion(suggestion)}
                                        >
                                            Assign
                                        </Button>
                                    </Box>
                                ))
                            ) : (
                                <Typography variant="body2">No suggestions available.</Typography>
                            )
                        )}

                    <Divider sx={{ marginY: 2 }}>OR</Divider>
                    <Box className="add-new-entry" sx={{ marginTop: 3 }}>
                        <Typography variant="h6">Manual Search and Add</Typography>
                        <Box display="flex" alignItems="center" marginBottom={2}>
                            <Typography variant="body1" sx={{ marginRight: 1 }}>Game:</Typography>
                            <TextField
                                fullWidth
                                margin="dense"
                                value={gameName}
                                onChange={(e) => setGameName(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                disabled={selectedTileIndex === null}
                            />
                        </Box>
                        {gameMatch && <Typography sx={{ color: 'green' }}>Match Found</Typography>}
                        <Box display="flex" alignItems="center" marginBottom={2}>
                            <Typography variant="body1" sx={{ marginRight: 1 }}>Studio:</Typography>
                            <TextField
                                fullWidth
                                margin="dense"
                                value={studio}
                                InputLabelProps={{ shrink: true }}
                                disabled
                            />
                        </Box>
                        {studioMatch && <Typography sx={{ color: 'green' }}>Match Found</Typography>}
                        <Button
                            variant="contained"
                            sx={{ marginTop: 2 }}
                            disabled={!gameMatch || !studioMatch || selectedTileIndex === null}
                            onClick={handleManualAssign}
                        >
                            Assign Match to Game
                        </Button>
                    </Box>
                    <Divider sx={{ marginY: 2 }}>OR</Divider>
                    <Button 
                        variant="outlined" 
                        onClick={handleOpenNewGameDialog} 
                        disabled={selectedTileIndex === null} // Disable if no tile is selected
                        >
                        Create New Game
                    </Button>
                    <Dialog open={newGameDialogOpen} onClose={handleCloseNewGameDialog}>
                        <DialogTitle>Create New Game</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Game Name"
                                fullWidth
                                value={gameName}
                                onChange={(e) => setGameName(e.target.value)}
                            />
                            <FormControl fullWidth margin="dense" disabled={newStudioName !== ''}>
                                <InputLabel>Studio</InputLabel>
                                <Select
                                    value={selectedStudioId}
                                    onChange={(e) => setSelectedStudioId(e.target.value)}
                                >
                                    {studios
                                        .sort((a, b) => a.Name.localeCompare(b.Name))
                                        .map((studio) => (
                                            <MenuItem key={studio.StudioID} value={studio.StudioID}>
                                                {studio.Name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <TextField
                                margin="dense"
                                label="New Studio Name"
                                fullWidth
                                value={newStudioName}
                                onChange={(e) => {
                                    setNewStudioName(e.target.value);
                                    if (e.target.value !== '') {
                                        setSelectedStudioId('unknown'); // Reset dropdown selection if typing in new studio name
                                    }
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseNewGameDialog}>Cancel</Button>
                            <Button
                                variant="contained"
                                onClick={handleCreateNewGame} // This function will be implemented separately
                                disabled={!gameName || (newStudioName === '' && selectedStudioId === 'unknown')}
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
    
    
    

}

export default AnalysisWorkbench;
