import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, LinearProgress, Box } from '@mui/material';
import axios from 'axios';

const URLBoxStatusTile = () => {
  const [usageData, setUsageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [websitesCount, setWebsitesCount] = useState(0);

  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  const fetchUsageData = async () => {
    try {
      const response = await axios.get(`${API_URL}/system-info/urlbox`, {
        headers: {
          'x-api-key': API_KEY
        }
      });
      setUsageData(response.data.usageData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWebsitesCount = async () => {
    try {
      const response = await axios.get(`${API_URL}/websites`, {
        headers: {
          'x-api-key': API_KEY
        }
      });
      setWebsitesCount(response.data.length); // Assuming the API returns an array of websites
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchUsageData();
    fetchWebsitesCount();
  }, []);

  const calculateRenderStatus = () => {
    if (!usageData || !websitesCount) return { status: 'OK', percentage: 100 };

    const rendersPerDay = websitesCount;
    const rendersRemaining = parseInt(usageData.rendersRemaining, 10);

    const resetDate = new Date(usageData.rendersReset);
    const currentDate = new Date();

    const daysUntilReset = Math.ceil((resetDate - currentDate) / (1000 * 60 * 60 * 24));

    const totalRendersExpected = daysUntilReset * rendersPerDay + rendersPerDay; // One more day deduction

    const percentage = (rendersRemaining / totalRendersExpected) * 100;

    return {
      status: rendersRemaining >= totalRendersExpected ? 'OK' : 'LOW',
      percentage
    };
  };

  const renderStatus = calculateRenderStatus();

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          URLBox Render Usage
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">Error fetching data.</Typography>
        ) : (
          <>
            <Typography variant="h6">Renders Allowed: {usageData.rendersAllowed}</Typography>
            <Typography variant="h6">Renders Used: {usageData.rendersUsed}</Typography>
            <Typography variant="h6">Renders Remaining: {usageData.rendersRemaining}</Typography>
            <Typography variant="h6">Reset Date: {new Date(usageData.rendersReset).toLocaleDateString()}</Typography>

            <Box mt={2}>
              <Typography variant="subtitle1">Remaining Renders Status:</Typography>
              <LinearProgress
                variant="determinate"
                value={renderStatus.percentage}
                sx={{
                  backgroundColor: renderStatus.status === 'LOW' ? 'red' : 'green',
                  height: '10px',
                }}
              />
              <Typography>
                {renderStatus.status === 'LOW'
                  ? 'You are likely to run out of renders!'
                  : 'Renders should last until the reset.'}
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default URLBoxStatusTile;
