import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, TableRow, TableCell, TableBody, TableHead, Typography, CircularProgress, Box } from '@mui/material';

const ManageModels = () => {
  const [models, setModels] = useState([]);
  const [activeModel, setActiveModel] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_ANALYST_URL;
  const API_KEY = process.env.REACT_APP_API_ANALYST_KEY;

  // Fetch models and active model on component mount and set interval for 10 seconds
  useEffect(() => {
    fetchModels();
    fetchActiveModel();
    const intervalId = setInterval(() => {
      fetchModels();
      fetchActiveModel();
    }, 10000); // Refresh every 10 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const fetchModels = async () => {
    try {
      const response = await axios.get(`${API_URL}/rekognition/models`, {
        headers: { 'x-api-key': API_KEY }
      });
      setModels(response.data.models);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching models:', error);
      setLoading(false);
    }
  };

  const fetchActiveModel = async () => {
    try {
      const response = await axios.get(`${API_URL}/rekognition/active-model`, {
        headers: { 'x-api-key': API_KEY }
      });
      setActiveModel(response.data.activeModelArn);
    } catch (error) {
      console.error('Error fetching active model:', error);
    }
  };

  const handleStartModel = async (modelArn) => {
    try {
      await axios.post(`${API_URL}/rekognition/start-model`, { modelArn }, {
        headers: { 'x-api-key': API_KEY }
      });
    } catch (error) {
      console.error('Error starting model:', error);
      alert('Failed to start model');
    }
  };

  const handleStopModel = async (modelArn) => {
    try {
      await axios.post(`${API_URL}/rekognition/stop-model`, { modelArn }, {
        headers: { 'x-api-key': API_KEY }
      });
    } catch (error) {
      const errorMessage = error.response?.data?.details || 'Failed to stop model';
      alert(`Error stopping model: ${errorMessage}`);
    }
  };

  const handleSetActiveModel = async (modelArn) => {
    try {
      const response = await axios.post(
        `${API_URL}/rekognition/active-model`,
        { 
          settingName: 'activeModelArn',  // This is what the Lambda function expects
          settingValue: modelArn          // Passing the modelArn as the settingValue
        },
        {
          headers: { 'x-api-key': API_KEY },
        }
      );
      setActiveModel(modelArn);

    } catch (error) {
      console.error('Error setting active model:', error.response ? error.response.data : error.message);

    }
  };

  const getModelName = (arn) => {
    const arnParts = arn.split('/');
    return arnParts[arnParts.length - 2]; // Extract the model name part
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Formats to local date and time string
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Manage Models
      </Typography>

      <Typography variant="h6" gutterBottom>
        Active Model: {activeModel ? getModelName(activeModel) : 'None'}
      </Typography>

      {models.length === 0 ? (
        <Typography>No models available</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>F1 Score</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map((model) => (
              <TableRow key={model.ProjectVersionArn}>
                <TableCell>{getModelName(model.ProjectVersionArn)}</TableCell>
                <TableCell>{model.Status}</TableCell>
                <TableCell>{model.EvaluationResult?.F1Score?.toFixed(2) ?? 'N/A'}</TableCell>
                <TableCell>{formatDate(model.CreationTimestamp)}</TableCell>
                <TableCell>
                  <Button 
                    onClick={() => handleStartModel(model.ProjectVersionArn)} 
                    variant="contained" 
                    color="primary" 
                    style={{ marginRight: '10px' }}
                    disabled={model.Status === 'RUNNING' || model.Status === 'STARTING'}
                  >
                    Start
                  </Button>
                  <Button 
                    onClick={() => handleStopModel(model.ProjectVersionArn)} 
                    variant="contained" 
                    color="secondary" 
                    style={{ marginRight: '10px' }}
                    disabled={model.Status === 'STOPPED' || model.Status === 'STARTING'}
                  >
                    Stop
                  </Button>
                  {activeModel === model.ProjectVersionArn ? (
                    <Typography variant="body2" color="primary" style={{ marginLeft: '10px' }}>
                      (Active)
                    </Typography>
                  ) : (
                    <Button onClick={() => handleSetActiveModel(model.ProjectVersionArn)} variant="outlined">
                      Set Active
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default ManageModels;
