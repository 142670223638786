import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Delete as DeleteIcon, Info as InfoIcon } from '@mui/icons-material';

const GamesStudiosPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [games, setGames] = useState([]);
  const [studios, setStudios] = useState([]);
  const [gamesByStudio, setGamesByStudio] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGamesAndStudios = async () => {
      try {
        // Fetch games
        console.log('Fetching games...');
        const gamesResponse = await axios.get(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/games`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
          },
        });
        if (gamesResponse.data && gamesResponse.data.games) {
          console.log('Games fetched:', gamesResponse.data.games);
          setGames(Array.isArray(gamesResponse.data.games) ? gamesResponse.data.games : []);
        } else {
          console.error('Games data is not an array:', gamesResponse.data);
          setGames([]);
        }

        // Fetch studios
        console.log('Fetching studios...');
        const studiosResponse = await axios.get(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/studios`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
          },
        });
        if (studiosResponse.data && studiosResponse.data.studios) {
          console.log('Studios fetched:', studiosResponse.data.studios);
          setStudios(Array.isArray(studiosResponse.data.studios) ? studiosResponse.data.studios : []);
        } else {
          console.error('Studios data is not an array:', studiosResponse.data);
          setStudios([]);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching games or studios:', error);
      }
    };

    fetchGamesAndStudios();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDeleteGame = async (gameId) => {
    if (window.confirm('Are you sure you want to delete this game?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_GAMES_STUDIOS_URL}/games/${gameId}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_GAMES_STUDIOS_KEY,
          },
        });
        setGames((prevGames) => prevGames.filter((game) => game.GameID !== gameId));
      } catch (error) {
        console.error('Error deleting game:', error);
      }
    }
  };

  const handleOpenDialog = (studio) => {
    setGamesByStudio(games.filter((game) => game.StudioID === studio.StudioID));
    setDialogTitle(`Games by ${studio.Name}`);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="Games" />
        <Tab label="Studios" />
      </Tabs>

      {selectedTab === 0 && (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Name</TableCell>
                <TableCell>Studio Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.map((game) => (
                <TableRow key={game.GameID}>
                  <TableCell>{game.Name}</TableCell>
                  <TableCell>
                    {studios.find((studio) => studio.StudioID === game.StudioID)?.Name || 'Unknown'}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteGame(game.GameID)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedTab === 1 && (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Studio Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studios.map((studio) => (
                <TableRow key={studio.StudioID}>
                  <TableCell>{studio.Name}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<InfoIcon />}
                      onClick={() => handleOpenDialog(studio)}
                    >
                      View Games
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Dialog for viewing games by studio */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gamesByStudio.map((game) => (
                <TableRow key={game.GameID}>
                  <TableCell>{game.Name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GamesStudiosPage;
