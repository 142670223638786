import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, View } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { Container, Typography, Grid } from '@mui/material';
import awsconfig from './aws-exports';

import Navigation from './Navigation';

//Configuration Pages
import ProxyConfiguration from './pages/ProxyConfiguration';
import WebsitesConfiguration from './pages/WebsitesConfiguration';

//Model Management and Testing
import ModelDetails from './pages/ModelDetails';
import AnalysisWorkbench from './pages/AnalysisWorkbench';

//Consumer Page
import TimeMachine from './pages/TimeMachine';

//Ops Screens
import ScreenshotErrors from './pages/ScreenshotErrors'
import GameNames from './pages/GameNames';

//Tiles
import StatusTile from './ui-components/StatusTile';
import URLBoxStatusTile from './ui-components/URLBoxStatusTile';

// Configure Amplify
Amplify.configure(awsconfig);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Authenticator>
          {({ signOut, user }) => (
            <>
              {user && <Navigation signOut={signOut} />}
              <Container>
                <View as="main">
                  <Routes>
                    <Route path="/proxy-configuration" element={<ProxyConfiguration />} />
                    <Route path="/time-machine" element={<TimeMachine />} />
                    <Route path="/websites-configuration" element={<WebsitesConfiguration />} />
                    <Route path="/model-details" element={<ModelDetails />} />
                    <Route path="/game-names" element={<GameNames />} />
                    <Route path="/screenshot-errors" element={<ScreenshotErrors />} />
                    <Route path="/analysis-workbench" element={<AnalysisWorkbench />} />
                    <Route
                      path="/"
                      element={
                        <>
                          {user && (
                            <>
                              <Typography variant="h5" gutterBottom>
                                Welcome to Slot Intel
                              </Typography>
                              <Grid container spacing={2}>
                                {/* Apply flexbox and flex: 1 for equal height */}
                                <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                                  <StatusTile sx={{ flex: 1 }} /> 
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                                  <URLBoxStatusTile sx={{ flex: 1 }} />
                                </Grid>
                              </Grid>
                            </> 
                          )}
                        </>
                      }
                    />
                  </Routes>
                </View>
              </Container>
            </>
          )}
        </Authenticator>
      </Router>
    </ThemeProvider>
  );
};

export default App;